import React from 'react'
import { RichTextJson } from '@types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import Image from '@partials/Image'
import '@sass/components/about/WhoAmI.scss'

type Props = {
  data: {
    text: {
      first: string
      second: RichTextJson
    }
    image: string
  }
}

const WhoAmI: React.FC<Props> = ({ data }) => {
  return (
    <div className="column is-12 whoami">
      <div className="column is-12 is-paddingless">
        <h2 className="section-title has-after has-after--bottom wow fadeIn" data-wow-duration="1.5s">
          Who am I
        </h2>
      </div>
      <div className="columns is-multiline">
        <div className="column is-6 whoami__left p-mb-0">
          <Image fadeType="fadeIn" animate={true} src={data.image} alt="laptop" wowDuration={1.5} />
        </div>
        <div className="column is-6 whoami__right p-mb-0">
          <p className="wow fadeIn" data-wow-delay="0.25s" data-wow-duration="1.5s">
            {data.text.first}
          </p>
          <div className="wow fadeIn" data-wow-delay="0.5s" data-wow-duration="1.5s">
            {documentToReactComponents(data.text.second.json, {
              renderNode: {
                [INLINES.HYPERLINK]: function Link(node, child) {
                  return (
                    <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>
                      {child}
                    </a>
                  )
                },
              },
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default WhoAmI
