import { useStaticQuery, graphql } from 'gatsby'
import { CTANode, CTAObject } from '@types'

/**
 * Get call to action data
 */
export const useCtaData = (): CTAObject => {
  const graphData = useStaticQuery(graphql`
    query ctaQuery {
      contentfulCta {
        copyTitle
        text {
          text
        }
        image {
          description
          file {
            url
          }
        }
        buttonText
        buttonUrl
      }
    }
  `)

  const { copyTitle, text, buttonText, buttonUrl, image }: CTANode = graphData.contentfulCta

  const data: CTAObject = {
    title: copyTitle,
    text: text.text,
    button: {
      text: buttonText,
      url: buttonUrl,
    },
    image: {
      url: image?.file?.url,
      alt: image?.description,
    },
  }

  return data
}
