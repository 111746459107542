import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@seo'
import PageHeader from '@pageHeader'
import WhoAmI from '@about/WhoAmI'
import Capabilities from '@about/Capabilities'
import FeaturedProjects from '@about/FeaturedProjects'
import Footer from '@footer'
import Cta from '@cta'
import { getFormattedSkills } from '@helpers/formatters'
import { PageProps } from '@types'

const About = ({ data }: PageProps): JSX.Element => {
  const {
    pageTitle,
    pageDescription,
    pageColor,
    titleFirst,
    titleSecond,
    excerptFirst,
    excerptSecond,
    template,
  } = data.contentfulPage
  const { whoAmIFirst, whoAmISecond, whoAmIImage, capabilitiesText, capabilitiesImage, skills } = template

  return (
    <>
      <Seo title="About" themeColor={pageTitle} description={pageDescription} />
      <PageHeader
        title={{
          first: titleFirst,
          second: titleSecond,
        }}
        excerpt={{
          first: excerptFirst,
          second: excerptSecond,
        }}
        color={pageColor}
      />
      <div className="column is-12 page">
        <WhoAmI
          data={{
            text: {
              first: whoAmIFirst.content[0].content[0].value,
              second: whoAmISecond,
            },
            image: whoAmIImage.file.url,
          }}
        />
        <Capabilities
          data={{
            text: capabilitiesText.content[0].content[0].value,
            image: capabilitiesImage.file.url,
            skills: getFormattedSkills(skills),
          }}
        />
        <FeaturedProjects />
        <Cta />
      </div>
      <Footer />
    </>
  )
}

export default About

export const query = graphql`
  query AboutPage($aboutPageId: String!) {
    contentfulPage(contentful_id: { eq: $aboutPageId }) {
      id
      pageColor
      pageTitle
      pageDescription
      titleFirst
      titleSecond
      excerptFirst
      excerptSecond
      template {
        ... on ContentfulTemplateAbout {
          id
          whoAmIFirst {
            content {
              content {
                value
              }
            }
          }
          whoAmISecond {
            json
          }
          whoAmIImage {
            file {
              url
            }
          }
          capabilitiesText {
            content {
              content {
                value
              }
            }
          }
          capabilitiesImage {
            file {
              url
            }
          }
          skills {
            id
            skillTitle
            skillImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
