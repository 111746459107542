import { SkillNode, SkillObject } from '@types'

/**
 * Get formated skills array
 * @param skills
 */
export const getFormattedSkills = (skillsData: SkillNode[]): SkillObject[] => {
  const skills: SkillObject[] = []

  skillsData.map(({ id, skillTitle, skillImage }) => {
    const formatedSkill = {
      id,
      title: skillTitle,
      image: {
        url: skillImage?.file?.url,
        alt: skillImage.description,
      },
    }

    return skills.push(formatedSkill)
  })

  return skills
}
